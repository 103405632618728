import { Component, OnInit } from '@angular/core';
import {TicketData} from '../../data/TicketData';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/local-storage.service';
import {NgxSpinner, NgxSpinnerService} from 'ngx-spinner';
import {ItemData} from '../../data/ItemData';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrls: ['./my-tickets.component.css']
})
export class MyTicketsComponent implements OnInit {

  ticketsList: Array<TicketData> = new Array<TicketData>();
  constructor(private router: Router, private localService: LocalStorageService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.localService.getAllData(ItemData.TICKETS).then((ventilators: Array<TicketData>) => {
      this.ticketsList = ventilators.sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
    });
  }

}
