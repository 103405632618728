import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {SpinnerComponent} from '../../components/spinner/spinner.component';
import {NgxSpinnerModule} from 'ngx-spinner';



@NgModule({
  imports: [
    CommonModule,
    NgxSpinnerModule
  ],
  declarations: [SpinnerComponent],
  exports: [
    CommonModule, NgxSpinnerModule, FormsModule, SpinnerComponent
  ]
})
export class SharedModule { }
